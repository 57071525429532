<template>
  <div class="bbr-programs--videos">
    <div v-if="programVideos.length" class="my-5">
      <v-card
        v-for="(grouping, groupIndex) in groupedVideos"
        :key="groupIndex"
        class="mb-8"
        outlined
      >
        <v-card-title class="primary lighten-4">
          Week {{ groupIndex }}
        </v-card-title>

        <template v-for="(video, videoIndex) in grouping">
          <v-card-text class="pa-0" :key="videoIndex">
            <router-link
              class="row clickable pa-5 pt-7 mx-0"
              :to="{
                name: 'video.details',
                params: { id: video.id },
                meta: { stage: 1 },
              }"
            >
              <v-col cols="2">
                <v-chip
                  :color="getDayColor(video.day)"
                  text-color="white"
                  small
                >
                  {{ getDay(video.day) }}
                </v-chip>
              </v-col>
              <v-col cols="2">
                {{ video.title }}
              </v-col>
              <v-col cols="2">
                <span
                  v-for="(trainingProgram, index) in video.training_programs"
                  :key="trainingProgram.id"
                >
                  <span v-if="index !== 0">, </span>
                  {{ trainingProgram.name }}
                </span>
              </v-col>
              <v-col cols="3">
                {{ video.description }}
              </v-col>
              <v-col cols="3">
                <v-btn
                  class="float-right"
                  color="secondary"
                  :loading="loading"
                  @click.stop="removeVideo($event, video)"
                  text
                >
                  Remove
                </v-btn>
              </v-col>
            </router-link>
          </v-card-text>

          <v-divider :key="`divider-${videoIndex}`" />
        </template>
      </v-card>
    </div>

    <no-list v-else details="No Videos" />

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import NoList from '@/components/elements/NoList'
import Video from '@/models/Video'

import { map, sortBy, groupBy, mapValues } from 'lodash'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProgramVideos',

  components: {
    NoList,
    ConfirmDialog,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      selectedProgram: (state) => state.programs.selectedProgram,
    }),

    ...mapGetters({
      programVideos: 'programs/getSelectedProgramVideo',
    }),

    groupedVideos() {
      let _videos = map(this.programVideos, (video) => {
        return {
          ...video,
          ...{
            day: video.program_video.day,
            week: video.program_video.week,
            program_video_id: video.program_video.id,
          },
        }
      })

      let _groupedByWeek = groupBy(_videos, 'week')

      return mapValues(_groupedByWeek, (video, index) => {
        return sortBy(video, ['day'])
      })
    },
  },

  methods: {
    ...mapMutations({
      removeProgramVideo: 'programs/removeProgramVideo',
    }),

    getDay(index) {
      let days = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
      ]

      return days[index]
    },

    getDayColor(index) {
      let colors = [
        'blue-grey',
        'blue',
        'orange',
        'pink',
        'green',
        'purple',
        'teal',
      ]

      return colors[index]
    },

    async removeVideo(event, video) {
      event.preventDefault()

      const confirm = await this.$refs.confirmDelete.open(
        'Remove video',
        'Are you sure you want to remove this video?'
      )

      if (!confirm) return

      this.loading = true

      const programVideo = new Video({ id: video.program_video_id })

      await programVideo.for(this.selectedProgram).delete()

      this.removeProgramVideo(video)

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border: solid;
  border-width: 0 0 thin 0;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.clickable {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6);
}
</style>
