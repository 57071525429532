import Program from '@/models/Program'

import { mapActions, mapMutations, mapState } from 'vuex'
import { upperFirst, map } from 'lodash'

export default {
  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      program: (state) => state.programs.selectedProgram,
    }),

    hasDeload() {
      return this.program.has_deload
    },

    isCompleted() {
      return this.program.completed_at
    },

    isArchived() {
      return this.program.archived_at
    },
  },

  methods: {
    ...mapActions({
      saveProgram: 'programs/saveProgram',
    }),

    ...mapMutations({
      updateDraftList: 'programs/updateProgramOnDraftList',
      updateCompletedList: 'programs/updateProgramOnCompletedList',
    }),

    saveForLater() {
      this.loading = true

      this.updateDraftList(this.program)

      this.timeOut(() => {
        this.loading = false
        this.$router.push({ name: 'draft.programs' })
      })
    },

    async saveAndComplete() {
      if (this.loading) return

      this.loading = true

      if (this.$attrs.id) {
        let { data } = await Program.include(['trainers']).find(this.$attrs.id)
        data.training_program_ids = map(data.training_programs, 'id')
        data.trainer_ids = map(data.trainers, 'id')

        data.completed = 1
        data.rich_description = this.program.rich_description
        delete data.ends_at
        delete data.starts_at

        this.saveProgram(data)
          .then(({ data }) => {
            this.updateCompletedList(data)
            this.timeOut(() => {
              this.loading = false
              this.$router.push({ name: 'completed.programs' })
            })
          })
          .catch(({ response }) => {
            this.timeOut(() => {
              this.loading = false
              if (response && response.status === 422) {
                this.$refs.errorToast.open({
                  color: 'red',
                  message: upperFirst(response.data.message),
                })
              }
            })
          })
      }
    },

    async save() {
      this.loading = true

      if (this.$attrs.id) {
        let { data } = await Program.include(['trainers']).find(this.$attrs.id)
        data.training_program_ids = map(data.training_programs, 'id')
        data.trainer_ids = map(data.trainers, 'id')
        data.rich_description = this.program.rich_description

        delete data.ends_at
        delete data.starts_at

        this.saveProgram(data)
          .then(({ data }) => {
            this.timeOut(() => {
              this.loading = false
            })
          })
          .catch(({ response }) => {
            this.timeOut(() => {
              this.loading = false
              if (response && response.status === 422) {
                this.$refs.errorToast.open({
                  color: 'red',
                  message: upperFirst(response.data.message),
                })
              }
            })
          })
        return data
      }
    },

    timeOut(callback, time = 500) {
      setTimeout(() => {
        callback()
      }, time)
    },
  },
}
